// src/firebase.js
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage'; // Firebase Storage'ı içe aktarın

// Firebase yapılandırma bilgileri
const firebaseConfig = {
  apiKey: "AIzaSyDWxMTEd9BdLT53NZ_uBojmRZcAtM7vDq4",
  authDomain: "besiktasdemo.firebaseapp.com",
  projectId: "besiktasdemo",
  storageBucket: "besiktasdemo.appspot.com", // storageBucket URL'sini doğru yapılandırın
  messagingSenderId: "771329123361",
  appId: "1:771329123361:web:3cb67fdc593b3769df6f60"
};

// Firebase uygulamasını başlat
const app = initializeApp(firebaseConfig);

// Firebase servislerini dışa aktar
export const auth = getAuth(app);
export const firestore = getFirestore(app);
export const storage = getStorage(app); // Storage nesnesini dışa aktarın
export default app;
