// src/components/AdminPanel.js
import React, { useEffect, useState } from 'react';
import { firestore } from '../firebase';
import { collection, getDocs, addDoc, doc, updateDoc, arrayUnion } from 'firebase/firestore';
import axios from 'axios';
import './AdminPanel.css';

function AdminPanel() {
  const [seasons, setSeasons] = useState([]);
  const [newSeason, setNewSeason] = useState('');
  const [playerName, setPlayerName] = useState('');
  const [playerAge, setPlayerAge] = useState('');
  const [playerAppearances, setPlayerAppearances] = useState('');
  const [playerAssists, setPlayerAssists] = useState('');
  const [playerGoals, setPlayerGoals] = useState('');
  const [playerPosition, setPlayerPosition] = useState('');
  const [playerNationality, setPlayerNationality] = useState('');
  const [selectedSeason, setSelectedSeason] = useState('');
  const [playerImage, setPlayerImage] = useState(null); // Resim dosyasını saklamak için

  // Sezonları Firestore'dan çekmek için yardımcı bir fonksiyon
  const fetchSeasons = async () => {
    try {
      const seasonsCollection = collection(firestore, 'seasons');
      const seasonSnapshot = await getDocs(seasonsCollection);
      const seasonList = seasonSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      console.log("Firestore'dan çekilen sezon verileri:", seasonList); // Verileri kontrol edin
      setSeasons(seasonList);
    } catch (error) {
      console.error("Veri çekme hatası: ", error);
    }
  };

  useEffect(() => {
    fetchSeasons();
  }, []);

  // Yeni sezon ekleme
  const handleAddSeason = async () => {
    try {
      await addDoc(collection(firestore, 'seasons'), {
        season: newSeason,
        players: [],
      });
      alert('Yeni sezon eklendi!');
      setNewSeason('');
      fetchSeasons(); // Yeni sezonu çekmek için
    } catch (error) {
      console.error("Sezon ekleme hatası: ", error);
    }
  };

  // Cloudinary'e resim yükleyip URL almak
  const uploadImageToCloudinary = async () => {
    if (!playerImage) {
      console.error("Resim dosyası seçilmedi.");
      alert("Lütfen bir resim dosyası seçin.");
      return null;
    }

    const formData = new FormData();
    formData.append('file', playerImage);
    formData.append('upload_preset', 'bnu130mi'); // Cloudinary'de ayarladığınız upload preset

    try {
      const response = await axios.post(
        `https://api.cloudinary.com/v1_1/doqhxrkqn/image/upload`, // Cloudinary'deki cloud name kısmı
        formData
      );
      console.log("Resim başarıyla yüklendi:", response.data); // Yanıtı konsolda kontrol edin
      return response.data.secure_url;
    } catch (error) {
      console.error("Resim yükleme hatası:", error);
      alert("Resim yükleme sırasında bir hata oluştu. Cloudinary ayarlarınızı kontrol edin.");
      return null;
    }
  };

  // Sezona oyuncu ekleme
  const handleAddPlayer = async () => {
    if (!selectedSeason) {
      alert("Lütfen bir sezon seçin!");
      return;
    }

    try {
      // Resmi Cloudinary'ye yükleyip URL'sini al
      const imageURL = await uploadImageToCloudinary();

      if (imageURL === null) {
        console.error("Resim yüklenemedi, oyuncu eklenemedi.");
        alert("Oyuncu eklenemedi, lütfen resmi kontrol edin.");
        return;
      }

      const seasonRef = doc(firestore, 'seasons', selectedSeason);
      await updateDoc(seasonRef, {
        players: arrayUnion({
          name: playerName,
          age: playerAge,
          appearances: playerAppearances,
          assists: playerAssists,
          goals: playerGoals,
          position: playerPosition,
          nationality: playerNationality,
          imageURL: imageURL, // Cloudinary URL'si
        }),
      });
      alert('Oyuncu eklendi!');

      // Alanları temizle
      setPlayerName('');
      setPlayerAge('');
      setPlayerAppearances('');
      setPlayerAssists('');
      setPlayerGoals('');
      setPlayerPosition('');
      setPlayerNationality('');
      setPlayerImage(null);
    } catch (error) {
      console.error("Oyuncu ekleme hatası:", error);
      alert("Oyuncu ekleme sırasında bir hata oluştu.");
    }
  };

  return (
    <div className="admin-panel">
      <h1>Admin Paneli</h1>
      
      {/* Yeni Sezon Ekleme Formu */}
      <div className="form-section">
        <h2>Yeni Sezon Ekle</h2>
        <input
          type="text"
          placeholder="Sezon Adı (örn: 2024-2025)"
          value={newSeason}
          onChange={(e) => setNewSeason(e.target.value)}
          className="input-field"
        />
        <button onClick={handleAddSeason} className="button">Sezon Ekle</button>
      </div>

      {/* Oyuncu Ekleme Formu */}
      <div className="form-section">
        <h2>Oyuncu Ekle</h2>
        <select
          value={selectedSeason}
          onChange={(e) => setSelectedSeason(e.target.value)}
          className="input-field"
        >
          <option value="">Sezon Seçin</option>
          {seasons.map((season) => (
            <option key={season.id} value={season.id}>
              {season.season || season.id}
            </option>
          ))}
        </select>
        <input type="text" placeholder="Oyuncu Adı" value={playerName} onChange={(e) => setPlayerName(e.target.value)} className="input-field" />
        <input type="text" placeholder="Yaş" value={playerAge} onChange={(e) => setPlayerAge(e.target.value)} className="input-field" />
        <input type="text" placeholder="Maç Sayısı" value={playerAppearances} onChange={(e) => setPlayerAppearances(e.target.value)} className="input-field" />
        <input type="text" placeholder="Asistler" value={playerAssists} onChange={(e) => setPlayerAssists(e.target.value)} className="input-field" />
        <input type="text" placeholder="Goller" value={playerGoals} onChange={(e) => setPlayerGoals(e.target.value)} className="input-field" />
        <input type="text" placeholder="Pozisyon" value={playerPosition} onChange={(e) => setPlayerPosition(e.target.value)} className="input-field" />
        <input type="text" placeholder="Uyruk" value={playerNationality} onChange={(e) => setPlayerNationality(e.target.value)} className="input-field" />
        
        {/* Resim Dosyası Seçici */}
        <input type="file" onChange={(e) => {
          const file = e.target.files[0];
          setPlayerImage(file);
          console.log("Seçilen resim dosyası:", file); // Seçilen dosyayı kontrol edin
        }} className="input-field" />
        
        <button onClick={handleAddPlayer} className="button">Oyuncu Ekle</button>
      </div>

      {/* Sezon ve Oyuncuları Görüntüleme */}
      {seasons.map((season) => (
        <div key={season.id} className="season-section">
          <h2>{season.season || season.id} Sezonu</h2>
          {season.players && season.players.map((player, index) => (
            <div key={index} className="player-info">
              <p>İsim: {player.name}</p>
              <p>Yaş: {player.age}</p>
              <p>Maç Sayısı: {player.appearances}</p>
              <p>Asistler: {player.assists}</p>
              <p>Goller: {player.goals}</p>
              <p>Pozisyon: {player.position}</p>
              <p>Uyruk: {player.nationality}</p>
              {player.imageURL && <img src={player.imageURL} alt={player.name} className="player-image" />}
            </div>
          ))}
        </div>
      ))}
    </div>
  );
}

export default AdminPanel;
