// src/App.js
import React, { useState, useEffect } from 'react';
import { auth } from './firebase';
import { signOut } from 'firebase/auth';
import AdminPanel from './components/AdminPanel';
import Login from './components/Login';

function App() {
  const [user, setUser] = useState(null);

  useEffect(() => {
    // Uygulama yüklendiğinde kullanıcıyı otomatik olarak çıkış yaptır
    signOut(auth).then(() => {
      console.log("Oturum kapatıldı.");
    }).catch((error) => {
      console.error("Oturum kapatma hatası: ", error);
    });

    // Oturum değişikliklerini izleme
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setUser(user);
    });
    return () => unsubscribe();
  }, []);

  return (
    <div>
      {user ? <AdminPanel /> : <Login />}
    </div>
  );
}

export default App;
